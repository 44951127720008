<template>
  <div class="main">
      <nav class="navbar navbar-expand navbar-light fixed-top">
    <div class="container"> <a class="navbar-brand" href="/dashboard">
        <img height="23vh" class="d-block" src="/img/LogoPortoBank1.svg">
      </a>
      <button class="navbar-toggler navbar-toggler-right border-0 collapsed" type="button" data-toggle="collapse" data-target="#navbar4" aria-expanded="false">
        <span class="navbar-toggler-icon small"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar4">
        <p class="small text-info title-space mb-2 mt-2">Negocie Porto Bank</p>
        <ul class="navbar-nav ml-auto text-center"> </ul>
        <div class="space1  d-none d-sm-block"> </div>
        <div class="text-center">
          <a class="btn btn-zul ml-auto" href="login3.html">Negociar</a>
        </div>
      </div>
    </div>
  </nav>
        <div class="capa-erro" style="background-color: #eef4f0;">
    <div class="container">
      <div class="row">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"><i class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"></i>
          <h4 class="py-4 text-primary">No momento não temos uma oferta disponível para você. </h4>
        </div>
      </div>
      <div class="row pb-5">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4">
          <h6 class="py-2 text-muted">Conte com a gente para ter um alívio financeiro! É só selecionar o produto e acionar o atendimento que preferir:</h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito </h4>
          <p><a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.&nbsp;<br><a href="tel:08007277477" target="_blank">0800 727 7477</a> - Demais localidades.&nbsp;<br><a href="tel:551133663216" target="_blank">+55 11 3366 3216</a> - Atendimento no exterior.&nbsp;<br>De segunda a sexta-feira, das 8h às 18h30.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito Empresarial </h4>
          <p><a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.<br><a href="tel:08007277477" target="_blank">0800-727-7477</a> - Demais localidades.&nbsp;<br>De segunda à sexta das 7h40 às 19h00.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Empréstimo e Financiamento </h4>
          <p><a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br>De segunda a sexta-feira, das 8h às 19h (exceto feriados).</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p><a href="tel:08007272745" target="_blank">0800 727 2745</a><br>Atendimento 24 horas.</p>
        </div>
      </div>
    </div>
  <div class="footer1 text-light py-4">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>

export default {
  name: "Erro",
  data: () => ({
    largura: null,
    links: [

    ]
  }),
  methods: {
    retry() {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  created() {
    this.largura = window.screen.width;

  },
  computed: {
  }
};
</script>
<style scoped>
img {
  max-width: 100%;
}

.footer1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que o conteúdo ocupe pelo menos 100% da altura da tela */
}

</style>
